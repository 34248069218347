import {
  loadIframe,
  loadPreconnect,
  loadStylesheet,
  appendCampaignQueryParam,
  triggerErrorTracking,
  getBlueConicProfileId
} from "./helper.js";
import ModalHandler from "./modalHandler";
import appDimensions from "./appDimensions";
const containerBaseUrl = process.env.donate_widget_container_base_url;
const wwidgetBaseUrl = process.env.donate_widget_app_base_url;

export default class WidgetHandler {
  constructor(opts) {
    if (!this.validateParameters(opts)) {
      throw new Error("missing or invalid parameters for constructor.");
    }

    this.widgetId = opts.id;
    this.widgetEnv = opts.env;
    this.widgetTargetSelector = opts.targetSelector;
    this.widgetType = opts.type;
    this.containerBaseUrl = containerBaseUrl;
    this.widgetBaseUrl = wwidgetBaseUrl;
    this.widgetAppOrigin = new URL(this.widgetBaseUrl).origin;
    this.wvusAppParams = opts.wvusAppParams || null;
  }

  init() {
    this.widgetTarget = window.document.querySelector(
      this.widgetTargetSelector
    );

    // if embed, load the iframe in the container tag using css fade in animation
    if (this.widgetType == "embed") {
      // Add Message Event To Get Page Info
      this.handleParentInfoMessage(this.widgetId, this.widgetEnv);
      this.handleParentErrorMessage(this.widgetId, this.widgetEnv);
      this.handleAdditionalOrderInfoMessage(this.widgetId, this.widgetEnv);
      this.handleParentEventData(this.widgetId, this.widgetEnv);

      // Create an iframe from src
      let embedSrc = `${this.widgetBaseUrl}/?widgetid=${this.widgetId}&widgetenv=${this.widgetEnv}`;
      embedSrc = appendCampaignQueryParam(embedSrc);

      this.addiFrameFromSrc(
        this.widgetTarget,
        this.widgetId,
        this.widgetAppOrigin,
        embedSrc,
        this.widgetEnv
      );
    } else {
      this.initModalHandler();
    }
  }

  validateParameters({ id, type, env, targetSelector }) {
    if (id && type && targetSelector && env) {
      return true;
    }

    return false;
  }

  // load configuration script to parent window
  loadConfigurationScript(widgetContainerBaseUrl, widgetEnv, widgetId) {
    const widgetConfigScript = document.createElement("script");
    widgetConfigScript.setAttribute(
      `src`,
      `${widgetContainerBaseUrl}/configs/${widgetEnv}/widget.${widgetId}.configs.js`
    );
    document.body.appendChild(widgetConfigScript);
  }

  addiFrameFromSrc(widgetTarget, widgetId, appOrigin, src, widgetEnv) {
    const self = this;
    const wigetRefId = Date.now();
    const widgetFrameId = `widgetframe${wigetRefId}`;
    const { width, height } = appDimensions.default;

    const shimmerDiv = document.createElement("div");
    const shimmerDivId = `widgetShimmer${wigetRefId}`;
    shimmerDiv.className = "donate-widget-shimmer";
    shimmerDiv.setAttribute("id", shimmerDivId);
    shimmerDiv.innerHTML = this.getShimmerHTML();
    shimmerDiv.style.width = width + "px";
    shimmerDiv.style.height = height + "px";

    widgetTarget.appendChild(shimmerDiv);

    const callback = function (event) {
      const source =
        event?.target?.contentWindow ||
          document.getElementById(widgetFrameId)?.contentWindow;

      if (!source) {
        self.renderErrorState();
        return;
      }

      self.sendInfoToWidget(source, appOrigin, widgetId, widgetEnv);

      setTimeout(() => {
        try {
          const shimmerElement = window.document.getElementById(shimmerDivId);
          shimmerElement.style.display = "none";
          window.document
            .getElementById(widgetFrameId)
            .classList.remove("loading");
          window.document
            .getElementById(widgetFrameId)
            .classList.add("fade-in");
          window.document.getElementById(widgetFrameId).style.display = "block";
          const widgetParentTarget = window.document.querySelector(
            self.widgetTargetSelector
          );
          widgetParentTarget.removeChild(shimmerElement);
        } catch (err) {
          console.log(err);
        }
      }, 1000);
    };

    const errorCallback = function (event) {
      self.renderErrorState();
      return;
    };

    loadIframe(
      {
        src: src,
        width: width,
        height: height,
        id: widgetFrameId,
        widgetId: widgetId,
        className: "wvusdonatewidgetiframe loading",
        parent: widgetTarget,
        style:
          "border:1px solid rgba(204, 204, 204, 0.5);border-radius:8px; box-sizing: content-box;",
      },
      callback,
      errorCallback,
      self
    );
  }

  initModalHandler() {
    // Init modal handler
    const modalHandler = new ModalHandler({
      widgetId: this.widgetId,
      widgetEnv: this.widgetEnv,
      containerBaseUrl: this.containerBaseUrl,
      appBaseUrl: this.widgetBaseUrl,
      appOrigin: this.widgetAppOrigin,
      widgetHandler: this,
    });

    if (this.widgetType === "modal") {
      modalHandler.addButtonToTarget(this.widgetTargetSelector);
    } else if (this.widgetType === "modal-link") {
      modalHandler.addLinkClickHandlers(this.widgetTargetSelector);
    }
  }

  sendInfoToWidget(source, appOrigin, widgetId, widgetEnv) {
    // Send Initial Info To Widget
    source.postMessage(
      JSON.stringify({
        widgetId,
        widgetEnv,
        status: "info",
        parentUrl: window.location.href,
        parentTitle: document.title,
      }),
      appOrigin
    );
  }

  
  safelyParsePostMessage(event) {
    if (!event?.data) {
      return null;
    }

    try {
      const parsedData = JSON.parse(event.data);
      return parsedData;
    } catch (err) {
      return null;
    }
  }

  handleParentErrorMessage(widgetId, widgetEnv) {
    const self = this;
    window.addEventListener("message", (event) => {
      const data = self.safelyParsePostMessage(event);
      // Set to error state
      if (
        data 
          && data.status === "error" 
          && data.widgetId 
          && data.widgetId.toString() === widgetId.toString()
      ) {
        triggerErrorTracking(data.widgetId);

        self.renderErrorState();
        return;
      }
    });
  }

  handleParentInfoMessage(widgetId, widgetEnv) {
    const self = this;
    window.addEventListener("message", (event) => {
      const data = self.safelyParsePostMessage(event);

      if (
        data
          && data.status === "parentinfo" 
          && data.widgetId === widgetId 
          && data.widgetEnv === widgetEnv
      ) {
        this.parentInfoSent = true;
        event.source.postMessage(
          JSON.stringify({
            ...data,
            parentUrl: window.location.href,
            parentTitle: window.document.title,
          }),
          event.origin
        );
      }
    });
  }

  handleAdditionalOrderInfoMessage(widgetId, widgetEnv) {
    const self = this;
    window.addEventListener("message", (event) => {

      const data = self.safelyParsePostMessage(event);

      if (
        data 
          && data.status === "additionalOrderInfo"
          && data.widgetId === widgetId 
          && data.widgetEnv === widgetEnv
      ) {
        const wvusAppParams =
          self.wvusAppParams || window?.wvusAppParams || null;
        const additionalOrderInfo = {};
        const blueConicId = getBlueConicProfileId();

        if (wvusAppParams && wvusAppParams.goal_ref) {
          additionalOrderInfo.goalRef = wvusAppParams.goal_ref;
        }

        if (blueConicId) {
          additionalOrderInfo.blueConicId = blueConicId;
        }

        if (wvusAppParams && wvusAppParams.goal_ref) {
          additionalOrderInfo.goalRef = wvusAppParams.goal_ref;
        }

        event.source.postMessage(
          JSON.stringify({
            ...data,
            ...additionalOrderInfo,
          }),
          event.origin
        );
      }
    });
  }

  handleParentEventData(widgetId) {
    window.addEventListener("message", (event) => {

      const data = this.safelyParsePostMessage(event);

      if (
        data
          && data.status === "eventData" 
          && data.widgetId === widgetId
      ) {
        const eventData = data.eventData;
        const eventKey = data.eventKey;

        // Fire Event For the DataLayer
        document.dispatchEvent(new CustomEvent(eventKey, {
          detail: eventData,
        }));
      }
    });
  }

  renderErrorState() {
    const errorWrapper = document.createElement("div");
    errorWrapper.className = "donate-widget__error-wrapper";
    errorWrapper.innerHTML = this.getErrorHTML();
    errorWrapper.style.width = "328px";
    errorWrapper.style.height = "330px";

    // load google font css 2
    loadPreconnect("https://fonts.googleapis.com", false);
    loadPreconnect("https://fonts.gstatic.com", true);
    loadStylesheet(
      "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap"
    );

    this.widgetTarget.innerHTML = "";
    this.widgetTarget.appendChild(errorWrapper);
  }

  getErrorHTML() {
    return `
      <div class="donate-widget__error">
        <img src="https://wvusstatic.com/donate-widget/assets/images/paper-airplane.svg" alt="Something went wrong" />
        <h2>Unable to Load</h2>
        <p>Please try again, or donate to the <a href="https://donate.worldvision.org/give/world-vision-fund-donate-lp" target="_blank" rel="noopener">World Vision Fund</a>.</p>
      </div>
    `;
  }

  getShimmerHTML() {
    return `
      <div class="widget-shimmer">
        <div class="spinner--shimmer toggle-shimmer" style="--element-height: 48px; --element-width: 281px">
          <div class="shimmer__background"></div>
        </div>
        <div class="shimmer-row">
          <div class="spinner--shimmer"
              style="--element-height: 44px; --element-width: 81px;">
              <div class="shimmer__background"></div>
          </div>

          <div class="spinner--shimmer"
              style="--element-height: 44px; --element-width: 81px;">
              <div class="shimmer__background"></div>
          </div>

          <div class="spinner--shimmer"
              style="--element-height: 44px; --element-width: 81px;">
              <div class="shimmer__background"></div>
          </div>
        </div>
        <div class="spinner--shimmer" style="--element-height: 50px; --element-width: 281px">
          <div class="shimmer__background"></div>
        </div>
      </div>
    `;
  }
}
