import "./assets/less/main.less";
import WidgetHandler from './widgetHandler';
import { getCookieVal, loadPreconnect, loadStylesheet } from "./helper.js";

(() => {
  const widget = document.currentScript;
  const widgetDataset = widget ? widget.dataset : {};
  const widgetId = "widgetId" in widgetDataset ? widgetDataset.widgetId : "";
  const widgetType =
    "widgetType" in widgetDataset ? widgetDataset.widgetType : "embed";
  const widgetTargetSelector =
    "widgetTarget" in widgetDataset ? widgetDataset.widgetTarget : "";
  const widgetEnv =
    "widgetEnv" in widgetDataset
      ? widgetDataset.widgetEnv
      : getCookieVal("SESSdonatewidgetenv")
      ? getCookieVal("SESSdonatewidgetenv")
      : "prod";

  const wvusAppParams = window.wvusAppParams || null;

  if (widgetId && widgetTargetSelector && widgetEnv) {
    const widgetHandler = new WidgetHandler({
      id: widgetId, 
      type: widgetType, 
      env: widgetEnv, 
      targetSelector: widgetTargetSelector,
      wvusAppParams
    });
  
    widgetHandler.init();
  } else if (!window.WidgetHandler) {
    // Add WidgetHandler To the window object
    window.WidgetHandler = WidgetHandler;
  }
})();