function windowVar(variableName) {
  return (typeof window !== "undefined" && window[variableName]) || undefined;
}

export function loadIframe(
  opts,
  callback = () => {},
  errorCallback = () => {},
  context = {},
  timeout = 15500
) {
  const { src, parent, widgetId, ...attrs } = (opts.src && opts) || {
    src: opts,
  };

  const iframe = document.createElement("iframe");
  const listAttrs = ["id", "width", "height", "style", "className"];
  const srcOrigin = new URL(src).origin;
  let iframeLoadComplete = false;

  if (!src) {
    throw new Error("iframe src required");
  }

  const timeoutId = setTimeout(() => {
    if (!iframeLoadComplete) {
      iframeLoadComplete = true;
      try {
        window.removeEventListener("message", messageLoadHandler);
      } catch (err) {
        // Do Nothing
      }

      errorCallback({ target: iframe });
    }
  }, timeout);

  const messageLoadHandler = (event) => {
    const eventOrigin = event.origin;
    const isAppIframeOrigin = eventOrigin === srcOrigin;
    const eventData =
      (isAppIframeOrigin && event.data && JSON.parse(event.data)) || {};
    const eventWidgetId = (isAppIframeOrigin && eventData.widgetId) || "";
    const isAppWidgetId = eventWidgetId === widgetId;
    const isAppMessage = isAppIframeOrigin && isAppWidgetId;

    // Update Iframe Widget
    if (isAppMessage) {
      const data = event.data && JSON.parse(event.data);

      if (data.appSize) {
        const { width, height } = data.appSize;
        iframe.width = width || attrs.width;
        iframe.height = (height && +height + 2) || attrs.height;
      }
    }

    // Execute Callback
    if (!iframeLoadComplete && isAppMessage) {
      iframeLoadComplete = true;

      try {
        clearTimeout(timeoutId);
        window.removeEventListener("message", messageLoadHandler);
      } catch (err) {
        // Do Nothing
      }

      callback({ target: iframe });
    }
  };

  window.addEventListener("message", messageLoadHandler);

  if (context.parentInfoSent === true) {
    // Why? - This is a hope to fire the messageLoadHandler if the iframe url has beem loaded before hand.
    const eventData = {
      origin: srcOrigin,
      data: JSON.stringify({
        widgetId,
      }),
    };

    // Fire the MessageLoadHandler as if the load Message event has been fired
    messageLoadHandler(eventData);
  }

  iframe.src = src;

  if (attrs && Object.keys(attrs).length) {
    listAttrs.forEach((attr) => {
      if (attrs[attr]) {
        iframe[attr] = attrs[attr];
      }
    });
  }

  if (parent?.appendChild) {
    parent.appendChild(iframe);
  }

  return iframe;
}

export function getCookieVal(name, decodeVal = true) {
  if (!windowVar("document") || !window.document.cookie) {
    return "";
  }
  const reCookieName = window.document.cookie.match(
    new RegExp("(^| )" + name + "=([^;]+)")
  );
  const cookieVal = reCookieName ? reCookieName[2] : "";

  if (decodeVal === true) {
    return decodeURIComponent(cookieVal);
  }

  return cookieVal;
}

export function loadPreconnect(url, crossOrigin) {
  const preconnect = document.createElement("link");
  preconnect.rel = "preconnect";
  if (crossOrigin) {
    preconnect.crossOrigin = true;
  }

  preconnect.href = url;
  document.body.appendChild(preconnect);
}

export function loadStylesheet(url) {
  const stylesheet = document.createElement("link");
  stylesheet.rel = "stylesheet";
  stylesheet.href = url;
  document.body.appendChild(stylesheet);
}

export function getCSSC() {
  const isWVDomain = /(^|^[^:]+:\/\/|[^.]+\.)worldvision\.org/g.test(
    window.location.origin
  );

  if (!isWVDomain) {
    return "";
  }

  const urlParams = new URLSearchParams(window.location.search);
  const campaignParamValue = urlParams.get("campaign");
  const campaignCookieValue = getCookieVal("campaign");

  return campaignParamValue || campaignCookieValue || "";
}

export function appendCampaignQueryParam(srcURL) {
  const cssc = getCSSC();
  const csscQueryParam = cssc ? `&campaign=${cssc}` : "";

  return srcURL + csscQueryParam;
}

export function triggerErrorTracking(widgetId = "") {
  const eventName = "error_view";
  const eventData = {
    event_category: "dw_error",
    error_message: "Unable to Load",
    error_type: "App Load Error",
    error_source_type: "Donate Widget Loader",
    donate_widget_id: widgetId,
  };
  const customEvent = new CustomEvent(eventName, {
    detail: eventData,
  });
  document.dispatchEvent(customEvent);
}

export function getBlueConicProfileId() {
  try {
    return window?.blueConicClient?.profile?.getProfile()?.getId() || null;
  } catch (err) {
    console.log(err);
    return null;
  }
}