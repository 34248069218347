import appDimensions from "./appDimensions";
import { appendCampaignQueryParam, triggerErrorTracking, getBlueConicProfileId } from "./helper.js";

export default class ModelHandler {
  constructor({
    widgetId,
    widgetEnv,
    widgetType,
    containerBaseUrl,
    appBaseUrl,
    appOrigin,
    widgetHandler,
  }) {
    this.widgetId = widgetId;
    this.widgetEnv = widgetEnv;
    this.widgetType = widgetType;
    this.widgetHandler = widgetHandler;
    this.wvusAppParams = widgetHandler.wvusAppParams;
    this.baseUrl = containerBaseUrl;
    this.appBaseUrl = appBaseUrl;
    this.appOrigin = appOrigin;
    this.parentUrl = window?.location?.href;
    this.parentTitle = window?.document?.title;
    this.isOpen = false;
    this.modalRef = `${Date.now()}`;
    this.imageCardHtml = "";
    this.errorHtml = this.getErrorHtml();
    this.overlayFrameId = `wvuswidgetoverlay${this.modalRef}`;
    this.buttonFrameId = `wvuswidgetbutton${this.modalRef}`;
    this.appDimensions = JSON.stringify(appDimensions);
    this.width = appDimensions.default.width;
    this.height = appDimensions.default.height;
    this.messageListener();

    // Handle escape key
    document.addEventListener("keydown", (e) => {
      if (e.key == "Escape" || e.key == "Esc" || e.code == 27) {
        this.close();
      }
    });
  }

  messageListener() {
    window.addEventListener("message", (e) => {
      try {
        const data = JSON.parse(e.data);
        if (
          data.status === "close" &&
          data.id === this.widgetId &&
          data.modalRef == this.modalRef
        ) {
          this.close();
        } else if (
          data.status === "open" &&
          data.id === this.widgetId &&
          data.modalRef == this.modalRef
        ) {
          this.open();
        } else if (
          data.status === "size" &&
          data.id === this.widgetId &&
          data.modalRef == this.modalRef
        ) {
          this.targetIframe.width = data.width + 4;
          this.targetIframe.height = data.height;
          this.width = data.appWidth || this.width;
          this.height = data.appHeight || this.height;
        } else if (
          data.status === "error" &&
          data.id === this.widgetId &&
          data.modalRef == this.modalRef
        ) {
          triggerErrorTracking(this.widgetId);
        }
      } catch (err) {
        // Do Nothing
      }
    });
  }

  getWvusAppParams() {
    const wvusAppParamsVal = this.wvusAppParams || window.wvusAppParams || "";
    return JSON.stringify(wvusAppParamsVal);
  }

  getGeneratedPageURL(code, type) {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob);
  }

  createIframeOverlay() {
    const iframe = document.createElement("iframe");
    iframe.style =
      "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999999999; opacity:1;";
    this.wvusAppParamsStr = this.getWvusAppParams();
    this.getBlueConicProfileId = getBlueConicProfileId;

    this.html = this.overlayHTML(this);
    iframe.id = this.overlayFrameId;
    iframe.src = this.getGeneratedPageURL(this.html, "text/html");
    return iframe;
  }

  createTargetIframe() {
    const iframe = document.createElement("iframe");
    iframe.style = "margin:0; padding:0;border:none;overflow:hidden;";
    const html = this.buttonHTML(this);
    iframe.id = this.buttonFrameId;
    iframe.width = 151;
    iframe.height = 51;
    iframe.src = this.getGeneratedPageURL(html, "text/html");
    this.targetIframe = iframe;
    return iframe;
  }

  addButtonToTarget(targetSelector) {
    const target = document.querySelector(targetSelector);
    this.target = target;
    const iframe = this.createTargetIframe();
    target.appendChild(iframe);
  }

  getIframeOverlay(refreshed = false) {
    if (!this.iframe || refreshed) {
      this.iframe = this.createIframeOverlay();
    }

    return this.iframe;
  }

  overlayHTML({
    widgetId,
    widgetEnv,
    baseUrl,
    appBaseUrl,
    appOrigin,
    width,
    height,
    modalRef,
    errorHtml,
    imageCardHtml,
    parentUrl,
    parentTitle,
    wvusAppParamsStr,
    getBlueConicProfileId
  }) {
    let embedSrc = `${appBaseUrl}/?widgetid=${widgetId}&amp;widgetenv=${widgetEnv}`;
    embedSrc = appendCampaignQueryParam(embedSrc);
    const blueConicId = getBlueConicProfileId() || "";

    return `
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <title>Donate Now</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" type='text/css' media='all' />
          <link rel='stylesheet' id='swing-css-css' href='https://wvusstatic.com/donate-widget/assets/swing/2.25.0/swing.min.css' type='text/css' media='all' />
          <script src="${baseUrl}/configs/${widgetEnv}/widget.${widgetId}.configs.js"></script>
          <style> 
            *, :after, :before {
              box-sizing: border-box;
            }

            body {
              position: relative;
              padding: 0;
              margin: 0;
              width: 100%;
              height: 100%;
              background: rgba(102,102,102,0.8);
            }

            .wrapper {
              padding: 0;
              margin: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            #close {
              position: absolute;
              display: block;
              top: calc(50% - ${height + 75}px / 2);
              right: calc(50% - ${width + 75}px / 2);
              height: 48px;
              width: 48px;
              background: transparent;
              border: none;
              color: #f0f0f0;
              font-size: 24px;
              cursor: pointer;
              padding: 12px;
            }

            .donate-widget__error-wrapper {
              padding: 0;
              margin: 0;
              border: 1px solid rgba(204, 204, 204, 0.5);
              border-radius: 8px;
              box-sizing: content-box;
              background: #f5f5f5;
            }
            
            .widget-shimmer {
              padding: 24px;
            }
            
            .donate-widget__error {
              width: 320px;
              height: 330px;
              text-align: center;
              padding: 24px;
              font-family: Lato,Helvetica,Arial,sans-serif;
              line-height: 1.556;
              color: #333;
            }

            .donate-widget__error img {
              width: 100%;
              max-width: 200px;
              margin: 35px auto 15px;
            }
          
            .donate-widget__error h2 {
              font-weight: 400;
              margin: 0 0 5px;
            }
          
            .donate-widget__error p {
              margin: 0;
            }

            .donate-widget__error a {
              color: #00acca;
            }

            .donate-widget__error a:hover {
              color: #006661;
            }

            .donate-widget__image-card {
              width: 450px;
              overflow-y: scroll;
              background: #fff;
              border-radius: 8px;
              margin-right: 8px;
            }

            /* Hide scrollbar for Chrome, Safari and Opera */
            .donate-widget__image-card::-webkit-scrollbar {
              display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .donate-widget__image-card {
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            }

            .donate-widget__card-img-wrapper {
              height: 150px;
              overflow: hidden;
              width: 100%;
            }

            .donate-widget__card-img-wrapper img {
              margin: auto;
              width: 100%;
            }

            .donate-widget__card-content-header {
              align-items: flex-start;
              display: flex;
              justify-content: space-between;
            }

            .donate-widget__card-content-header img {
              max-width: 100px;
            }

            .donate-widget__card-content-wrapper {
              padding: 20px;
            }

            .donate-widget__card-content-wrapper h2 {
              font-size: 22px;
              font-weight: 700;
              margin: 5px 0 5px 0;
              line-height: 1.2em;
              margin-right: 5px;
            }

            .donate-widget__card-content-wrapper p {
              margin: 0;
            }

            @media screen and (max-width: 800px) {
              .donate-widget__image-card {
                display: none;
              }

              #close {
                top: calc(50% - ${height + 100}px / 2) !important;
                right: calc(50% - ${width + 75}px / 2) !important;
              }
            }
          </style>
        </head>
        <body>
          <button id="close"><i class="fas fa-times"></i></button>
          <div id="wrapper" class="wrapper">
            <iframe id="wvusappiframe" src="${embedSrc}" width="${width}" height="${height}" class="wvusdonatewidgetiframe fade-in" style="border: none; border-radius: 8px;display:none; box-sizing: content-box;"></iframe>
            <div id="loader" class="spinner--wv-star">
              <div class="double-bounce1"></div>
              <div class="double-bounce2"></div>
            </div>
          </div>
          <script>
            const widgetConfig = window.wvusDonateWidgetConfig['${widgetId}'];
            const { showImageCard } = widgetConfig;
            const wrapper = document.getElementById("wrapper");
            const button = document.getElementById("close");
            const wvusappiframe = document.getElementById("wvusappiframe");
            const loader = document.getElementById("loader");
            const { origin: parentOrigin } = new URL("${parentUrl}");
            let loadComplete = false;

            if (showImageCard) {
              button.style.top = "calc(50% - ${height + 100}px / 2)";
              button.style.right = "calc(50% - ${width + 540}px / 2)";
            }
            
            // Display App Logic
            const displayApp = (timerId) => {
                if (!loadComplete) {
                  loadComplete = true;
                  loader.style.display = "none";
                  wrapper.removeChild(loader);
                  wvusappiframe.style.display = "block";

                  if (showImageCard) {
                    // Increase Height By 30 Pixels
                    wvusappiframe.height = +(${height}) + 30;

                    const imgCard = document.createElement('div');
                    imgCard.classList.add("donate-widget__image-card");
                    const logoEl = widgetConfig.showLogo ? '<img src="https://wvusstatic.com/2020/logos/wvus/logo-wv-black-text.svg" alt="World Vision logo" />' : '';
                    imgCard.innerHTML = \`${imageCardHtml}\`;
                    imgCard.innerHTML =\`
                      <div class="donate-widget__card-img-wrapper">
                        <img src="\${widgetConfig.imageUrl}" alt="\${widgetConfig.imageAlt}" />
                      </div> 
                      <div class="donate-widget__card-content-wrapper">
                        <div class="donate-widget__card-content-header">
                          <h2>\${widgetConfig.headerText}</h2>
                          \${logoEl}
                        </div>
                        <div class="donate-widget__card-content">
                          \${widgetConfig.content}
                        </div>
                      </div>
                    \`
                    imgCard.style.height = +(${height}) + 30;
                    wrapper.prepend(imgCard);
                  }
                }
            }

            const errorTracking = () => {
              window.parent.postMessage(JSON.stringify({status: "error", id: "${widgetId}", modalRef:${modalRef}}), "*");
            }
            
            const displayError = (timerId) => {
              if (!loadComplete) {
                  errorTracking();
                  loadComplete = true;
                  loader.style.display = "none";
                  wrapper.removeChild(loader);
                  wvusappiframe.style.display = "block";
                  wrapper.removeChild(wvusappiframe);
                  wrapper.innerHTML = \`${errorHtml}\`;
                }
            }

            // Backup Timer
            const timerId = setTimeout(displayError, 15500);
            
            const messageEventHandler = (event) => {
              try {
                const data = event.data && JSON.parse(event.data) || "";

                if (data && data.status === "error" && data.widgetId == "${widgetId}") {
                  clearTimeout(timerId);
                  displayError(timerId);
                }

                if (data && data.status === "close" && data.widgetId == "${widgetId}") {
                  window.parent.postMessage(JSON.stringify({status: "close", id: "${widgetId}", modalRef:${modalRef}}), "*");
                  wvusappiframe.contentWindow.postMessage(JSON.stringify({
                    command: "reset",
                    widgetId: "${widgetId}"
                  }), "${appOrigin}");
                }

                if (data && data.status === "load" && data.widgetId === "${widgetId}") {
                  clearTimeout(timerId);
                  displayApp(timerId);

                  // Send Initial Info To Widget 
                  wvusappiframe.contentWindow.postMessage(JSON.stringify({
                    widgetId: "${widgetId}",
                    widgetEnv: "${widgetEnv}",
                    parentUrl: "${parentUrl}",
                    parentTitle: "${parentTitle}",
                    parentOrigin: parentOrigin,
                    status: "info"
                  }), "${appOrigin}");
                }

              } catch(err) {
                // console.log('modal iframe error', err);
              }
            }
            
            // Parent Info
            window.addEventListener("message", (event) => {
              try {
                const data = JSON.parse(event.data);
        
                if (
                  data.status === "parentinfo" &&
                  data.widgetId === "${widgetId}" &&
                  data.widgetEnv === "${widgetEnv}"
                ) {
                  event.source.postMessage(
                    JSON.stringify({
                      ...data,
                      parentUrl: "${parentUrl}",
                      parentTitle: "${parentTitle}",
                      parentOrigin: parentOrigin
                    }),
                    event.origin
                  );
                }
              } catch (err) {
                // Do Nothing
              }
            });

            // Get Additional Info
            window.addEventListener("message", (event) => {
              try {
                const data = JSON.parse(event.data);
                    
                if (
                    data.status === "additionalOrderInfo" &&
                    data.widgetId === "${widgetId}" &&
                    data.widgetEnv === "${widgetEnv}"
                ) {
                  const wvusAppParams = JSON.parse('${wvusAppParamsStr}');
                  const blueConicId = "${blueConicId}";
                  const additionalOrderInfo = {};
                    
                  if (wvusAppParams && wvusAppParams.goal_ref) {
                    additionalOrderInfo.goalRef = wvusAppParams.goal_ref;
                  }

                  if (blueConicId) {
                    additionalOrderInfo.blueConicId = blueConicId;
                  }

                  event.source.postMessage(
                    JSON.stringify({
                      ...data,
                      ...additionalOrderInfo
                    }),
                    event.origin
                  );
                }
              } catch (err) {
                // Do Nothing
              }
            });

            // Hoisted DL events
            window.addEventListener("message", (event) => {
              try {
                const data = JSON.parse(event.data);

                if (
                  data.status === "eventData" &&
                  data.widgetId === widgetId
                ) {
                  const eventData = data.eventData
                  const eventKey = data.eventKey;

                  // Fire Event For the DataLayer
                  document.dispatchEvent(new CustomEvent(eventKey, {
                    detail: eventData,
                  }));
                }
              } catch (err) {
                // Ignore error
              }
            });

            // Load App After Delay
            window.addEventListener("message", messageEventHandler);

            button.addEventListener("click", () => {
              window.parent.postMessage(JSON.stringify({status: "close", id: "${widgetId}", modalRef:${modalRef}}), "*");
              wvusappiframe.contentWindow.postMessage(JSON.stringify({
                command: "reset",
                widgetId: "${widgetId}"
              }), "${appOrigin}");
            });

            // Handle overlay click 
            wrapper.addEventListener("click", () => { 
              window.parent.postMessage(JSON.stringify({status: "close", id: "${widgetId}", modalRef:${modalRef}}), "*");
              wvusappiframe.contentWindow.postMessage(JSON.stringify({
                command: "reset",
                widgetId: "${widgetId}"
              }), "${appOrigin}");
            });
          </script>
        </body>
      </html>
    `;
  }

  buttonHTML({ widgetId, widgetEnv, baseUrl, appDimensions, modalRef }) {
    return `
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <style> 
            *, :after, :before {
              box-sizing: border-box;
            }

            body {
              padding: 0;
              margin: 0;
              background: transparent;
            }

            #open {
              opacity: 0;
            }
          </style>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" type='text/css' media='all' />
          <link rel="stylesheet" href="https://wvusstatic.com/donate-widget/assets/swing/2.25.0/swing.min.css" type='text/css' media='all' />
          <script src="${baseUrl}/configs/${widgetEnv}/widget.${widgetId}.configs.js"></script>
          <style>
          body { background-color: transparent; }
          .btn--neo {
            box-shadow: none;
          }
          </style>
        </head>
        <body>
          <button id="open" class="btn btn-primary btn--neo"><i id="icon" class="fas fa-heart"></i> <span id="text"></span></a></button>
          <script>
            const widgetConfig = window.wvusDonateWidgetConfig['${widgetId}'];
            const { widgetLauncherCtaText, widgetLauncherFaIcon } = widgetConfig;
            const dimensionSizes = JSON.parse('${appDimensions}');

            function getAppDimension({ frequencies, checkoutType }) {
              let size = "default";
            
              if (frequencies && Object.keys(frequencies).length <= 1 && checkoutType === "cart") {
                size = "small";
              }
            
              return dimensionSizes[size] || {};
            }

            const button = document.getElementById("open");
            const buttonText = document.getElementById("text");
            const buttonIcon = document.getElementById("icon");
            const closeButton = document.getElementById("close");

            buttonIcon.className = widgetLauncherFaIcon && widgetLauncherFaIcon !== "" ? widgetLauncherFaIcon : "";
            buttonText.textContent = widgetLauncherCtaText || "Donate";
            button.addEventListener("click", () => {
              window.parent.postMessage(JSON.stringify({status: "open", id: "${widgetId}", modalRef: ${modalRef}, widgetConfig: widgetConfig}), "*");
            });

            const { width, height } = getAppDimension(widgetConfig);

            setTimeout(() => {
              const button = document.getElementById("open");
              button.style.opacity = 1;

              window.parent.postMessage(JSON.stringify({
                status: "size", 
                id: "${widgetId}",
                modalRef:${modalRef},
                width: button.offsetWidth,
                height: button.offsetHeight,
                appWidth: width,
                appHeight: height
              }), "*");
            }, 1000);

            // Handle escape key
            document.addEventListener("keydown", (e) => { 
              if (e.key == 'Escape' || e.key == 'Esc' || e.code == 27 ) {
                window.parent.postMessage(JSON.stringify({status: "close", id: "${widgetId}", modalRef: ${modalRef}, widgetConfig: widgetConfig}), "*");
              }
            });
          </script>
        </body>
      </html>
    `;
  }

  getErrorHtml() {
    return `<div class="donate-widget__error-wrapper fade-in"><div class="donate-widget__error">
      <img src="https://wvusstatic.com/donate-widget/assets/images/paper-airplane.svg" alt="Something went wrong" />
      <h2>Unable to Load</h2>
      <p>Please try again, or donate to the <a href="https://donate.worldvision.org/give/world-vision-fund-donate-lp" target="_blank" rel="noopener">World Vision Fund</a>.</p>
    </div></div>`;
  }

  getPageContainer() {
    if (!this.pageContainer) {
      this.pageContainer = document.querySelector("body");
    }

    return this.pageContainer;
  }

  addLinkClickHandlers(linkSelector) {
    const self = this;
    const linkElements = window.document.querySelectorAll(linkSelector);

    if (!linkElements.length) {
      return false;
    }

    const linkHandlers = [...linkElements].map((elem) => {
      const linkHandler = (e) => {
        try {
          e.preventDefault();
          self.open();
        } catch (err) {
          // Silence Error
        }
      };

      elem.addEventListener("click", linkHandler);

      return linkHandler;
    });

    self.linkElements = linkElements;
    self.linkHandlers = linkHandlers;

    return true;
  }

  removeLinkClickHandlers() {
    if (this.linkElements?.length && this.linkHandlers?.length) {
      this.linkHandler.forEach((elem, index) => {
        elem.removeEventListener("click", this.linkHandlers[index]);
      });
    }
  }

  open(refreshed = false) {
    if (this.isOpen) {
      return;
    }
    try {
      if (
        !this.getPageContainer().querySelectorAll(`#${this.overlayFrameId}`)
          .length
      ) {
        this.getPageContainer().appendChild(this.getIframeOverlay(refreshed));
      }

      this.getIframeOverlay().style.display = "block";
      this.isOpen = true;
    } catch (err) {
      console.log(err);
    }
  }

  close() {
    if (!this.isOpen) {
      return;
    }

    try {
      this.getIframeOverlay().style.display = "none";
      this.isOpen = false;
    } catch (err) {
      console.log(err);
    }
  }
}
